$bearbox_blue: rgb(0, 122, 201);
$bearbox_light_blue: rgb(62, 151, 209);
$bearbox_orange: rgb(248, 149, 0);
$white: #fff;
$dark_grey: #333;
$dark_text: #000;

/* Change theme-colors */
$theme-colors: (primary: $bearbox_blue,
secondary: $bearbox_light_blue,
success: $bearbox_orange,
info: #64c3d2,
warning: #f5e74a,
danger: #820410);

$font-size-base: 1.25rem;

/* Override dropdowns */
$dropdown-min-width: 1rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 3px;
$dropdown-spacer: 0.125rem;
// $dropdown-font-size: $font-size-base;
// $dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: $white;
$dropdown-border-radius: 0;
$dropdown-border-width: 3px;
$dropdown-inner-border-radius: 0;
$dropdown-divider-bg: $white;
// $dropdown-divider-margin-y: $spacer * .5;
// $dropdown-box-shadow: $box-shadow;

$dropdown-link-color: $bearbox_blue;
$dropdown-link-hover-color: shade-color($bearbox_blue, 10%);
// $dropdown-link-hover-bg: $gray-200;

$dropdown-link-active-color: $bearbox_orange;
$dropdown-link-active-bg: $white;

// $dropdown-link-disabled-color: $gray-500;

// $dropdown-item-padding-y: $spacer * .25;
// $dropdown-item-padding-x: $spacer;

// $dropdown-header-color: $gray-600;
// $dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x;

$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;
$btn-border-width: 1px;

// Allows for customizing button radius independently from global border radius
$border-radius: 0;
$btn-border-radius: 0;
$input-bg: $white;
$border-color: $dark_grey;
$input-border-color: $dark_grey;
$input-group-addon-border-color: $dark_grey;

// Navbar toggler customisation
$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.5rem;
$navbar-toggler-font-size: 1.2rem;
$navbar-toggler-border-radius: 1px;
$navbar-toggler-focus-width: 0;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out;

$navbar-light-color: $bearbox_blue;
$navbar-light-hover-color: $bearbox_light_blue;
$navbar-light-active-color: $bearbox_light_blue;
// $navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: none;

// Importing bootstrap completely, so we don't need to re-import from anywhere else
// boostrap scss kept in a separate directory from scss so it isn't converted to css
@import "~bootstrap/scss/bootstrap.scss";
// Import for the carousel
@import "~slick-carousel/slick/slick.scss";

$cursor-path: "~/src/cursors" !default;

// Keeping the fonts local
$font-path: "~/src/fonts" !default;

@font-face {
  font-family: 'Raleway';
  src: url("#{$font-path}/Raleway-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("#{$font-path}/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: 'Oswald';
  src: url("#{$font-path}/Oswald-SemiBold.ttf") format("opentype");
  font-weight: 600;
  font-style: regular;
}

html body.bbx-dark {
  background-color: $dark_grey;
}

html body {
  h1,
  p,
  a,
  li,
  label {
    font-style: normal;
    font-weight: 300;
    line-height: 170%;
  }

  a:link,
  a:hover,
  a:active,
  a:visited {
    color: $bearbox_light_blue;

    &.btn {
      color: $white;
    }
  }

  h1 {
    font-family: Oswald, sans-serif !important;
  }

  h2,
  h3,
  h4,
  h5,
  p,
  a,
  li,
  label,
  .small {
    font-family: Raleway, sans-serif !important;
  }
}

.h1orange {
  @extend h1;

  font-size: 2.4rem;
  padding: 0.5rem 1rem;
  margin: 0;
  background-color: $bearbox_blue;
  transition: transform 1.5s ease-out;
  will-change: transform;
  display: inline-block;
  width: min-content;

  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 4rem;
  }

  &.genericpage {
    width: auto;
  }
}

header {
  background-color: $white;
  padding-top: 1rem;
  border-bottom: 10px solid $bearbox_blue;

  nav.navbar {
    padding: 0;

    .container {
      margin-bottom: -2px;

      #navbarCollapse {
        text-align: center;
        border-top: 10px solid $bearbox_blue;

        @include media-breakpoint-up(lg) {
          border: 0;
        }

        li.dropdown-item {
          text-align: center;
          padding: 3px 0 0 0;

          a {
            text-decoration: none;
          }

          @include media-breakpoint-up(lg) {
            text-align: start;
            padding: 2px 12px;
          }
        }
      }

      .localeflag {
        height: 35px;
        width: 54px;
        vertical-align: bottom;
      }

      #logo {
        width: 225px;
      }

      .logo-link {
        margin-bottom: -8px;
        margin-right: 2rem;
      }

      a {
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 0;

        @include media-breakpoint-up(lg) {
          &.nav-link.login {
            background-color: $bearbox_blue;
            color: $white;
            padding-top: 7px;
            padding-bottom: 4px;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }

      ul.navbar-nav a {
        &.nav-link {
          color: $bearbox_blue;
          padding-top: 0.8rem;
          padding-left: 1rem;
          padding-right: 1rem;
          padding-bottom: 2px;
        }
      }
    }
  }

  #flagdropdown.dropdown ul.dropdown-menu {
    min-width: 0;
    padding: 0;
    margin: 0;
    margin-left: -3px;
    border: 3px solid $white;
    border-radius: 0%;

    .dropdown-item {
      padding: 3px 0 0 0;
    }
  }

  .flaglist-horizontal {
    text-align: center;
    margin: 0.5rem;

    a.sm-flag {
      padding: 0.25rem;
      width: 54px;
      text-decoration: none;
    }
  }
}

.homepage-main {
  // Top margin to compensate for the video, which has absolute positioning
  margin-top: calc(100vh - 64px);
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    margin-top: 79vh;
  }

  .customer-recommendations-container {
    background: $white;

    .trusted-by p {
      text-align: right;
    }

    .rollinglist-wrapper {
      padding: 0;

      .rollinglist {
        margin: 1rem 0;

        div div.customer-frame {
          display: table;
          height: 100px;
          margin-right: 3rem;

          div.customer-centred {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

// video controls
.video-active {
  cursor:
    url("#{$cursor-path}/pause.png"),
    default;

  &.playing {
    cursor: url("#{$cursor-path}/pause.png") default;
  }

  &.paused {
    cursor:
      url("#{$cursor-path}/play.png"),
      default;
  }
}

.hp-hero-container {
  position: absolute;
  top: 64px;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: calc(100% - 64px);

  @include media-breakpoint-up(lg) {
    height: 80%;
  }

  video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
  }
}

.bkg-dark {
  background-color: $dark-grey;
  color: $white;
}

.bkg-light {
  background-color: $white;
  color: $dark_text;
}

.padded-wrapper {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .explainer-text {
    padding: 4rem;

    img.arrowflash {
      position: absolute;
      width: 3rem;
      height: 3rem;
      fill: #fff;
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;

      &:nth-child(odd) {
        transform: rotate(180deg);
        top: 0;
        right: 0;
      }

      &:nth-child(even) {
        bottom: 0;
        left: 0;
      }
    }
  }

  .feature-text {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @include media-breakpoint-up(md) {
    padding-top: 4rem;
    padding-bottom: 4rem;

    .feature-text {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.image-hero {
  padding-top: 9rem;
  padding-bottom: 9rem;
  background-size: cover;
}

#herotextcontainer {
  width: 80%;
  // For each breakpoint, create a rule that adds the padding you would usually have with a container
  // 100% viewport width minus container max width / 2
  @each $breakpoint,
    $container-max-width in $container-max-widths {
    @if $breakpoint !=sm {
      @include media-breakpoint-up($breakpoint) {
        width: calc(#{$container-max-width} - 2rem);
      }
    }
  }

  max-width: calc(100% - 2rem);
  padding: 1rem;
  position: absolute;
  top: 8vh;
  color: $dark_text;

  #herotext {
    min-width: 90%;
    width: min-content;
    visibility: hidden;

    @include media-breakpoint-up(md) {
      min-width: 50%;
      padding: 1.5rem 0;
    }

    @include media-breakpoint-up(lg) {
      min-width: 40%;
      padding: 2rem 4rem;
      margin-left: 3rem;
    }

    .word {
      @extend .h1orange;

      font-size: 3rem;
      line-height: 140% !important;

      @include media-breakpoint-up(sm) {
        font-size: 10vh;
      }
    }
  }
}

.arrowflashinline {
  transform: rotate(225deg);
  width: 2rem;
  margin-left: 0.5rem;

  &.smallarrow {
    width: 1.5rem;
    margin-left: 0;
  }
}

// Feature blocks
section.feature-blocks {
  // Homepage featured
  .featured-container {
    .colourise {
      filter: saturate(0);
      -webkit-transition: 3s filter ease 0.5s, 0.4s transform ease-out 0.5s;
      -moz-transition: 3s filter ease 0.5s, 0.4s transform ease-out 0.5s;
      -o-transition: 3s filter ease 0.5s, 0.4s transform ease-out 0.5s;
      transition: 3s filter ease 0.5s, 0.4s transform ease-out 0.5s;

      &.active {
        filter: saturate(150%);
      }
    }

    &:nth-child(odd) {
      @extend .bkg-light;

      @include media-breakpoint-up(md) {
        .colourise {
          transform: translateX(-145px);
        }
      }

      // text
      .col:nth-child(1) {
        @extend .order-2;
        @extend .offset-md-1;
      }
      // image
      .col:nth-child(2) {
        @extend .order-1;
      }
    }

    &:nth-child(even) {
      @extend .bkg-dark;

      @include media-breakpoint-up(md) {
        .colourise {
          transform: translateX(145px);
        }
      }

      // text
      .col:nth-child(1) {
        @extend .order-2;
        @extend .order-md-1;
      }
      // image
      .col:nth-child(2) {
        @extend .order-1;
        @extend .order-md-2;
        @extend .offset-md-1;
      }
    }

    img,
    video {
      width: calc(100% + var(--bs-gutter-x, 0.75rem) * 2);
      margin: 0 calc(var(--bs-gutter-x, 0.75rem) * -1);
      height: 400px;
      object-fit: cover;

      @include media-breakpoint-up(md) {
        width: 100%;
        margin: 0;
      }
    }
  }

  // Features page
  .bearbox-features-container {
    img.arrowflash {
      position: absolute;
      width: 3rem;
      height: 3rem;
      fill: #fff;
      margin: 1.6rem;
      transform: rotate(180deg);
      top: 0;
      right: 0;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    .bbx-feature-text {
      @include media-breakpoint-up(md) {
        padding: 3rem;
      }
    }

    .col:nth-child(3) {
      @extend .order-3;

      .bbx-feature-quote {
        background-color: #ccc;

        blockquote {
          border-left: 5px solid $bearbox_blue;
          color: $bearbox_blue;
        }

        figcaption {
          text-align: right;
        }
      }
    }

    &:nth-child(odd) {
      // text
      .col:nth-child(1) {
        @extend .order-2;
      }

      // image
      .col:nth-child(2) {
        @extend .order-1;
      }
    }

    &:nth-child(even) {
      // text
      .col:nth-child(1) {
        @extend .order-2;
        @extend .order-md-1;
      }

      // image
      .col:nth-child(2) {
        @extend .order-1;
        @extend .order-md-2;
      }

      img.arrowflash {
        left: 0;
        transform: rotate(90deg);
      }
    }

    .full-cover {
      img,
      video {
        width: 100%;
        height: 100%;
        min-height: 400px;
        // max-height: 500px;
        margin: 0;
        object-fit: cover;
      }
    }
  }

  // Other sections
  .hp-section {
    background-size: cover;
    background-position: center;

    .col {
      background-color: $white;
      padding: 2rem;
    }
  }
}

.orange-block {
  padding: 1.5rem;
  background-color: $bearbox_orange;
  text-align: center;

  a {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    border: 1px solid $dark_grey;
    background-color: $white;
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    color: $dark_grey;

    &.active {
      background-color: $bearbox_blue;
      color: $white;
    }
  }
}

// Full width link
a.full-width-link {
  text-decoration: none;

  &:link,
  &:hover,
  &:visited,
  &:active {
    .container-fluid {
      padding: 1.5rem;
      background-color: $bearbox_orange;
      text-align: center;

      span {
        @extend h2;

        color: $white;
        font-weight: bold;

        img.arrowflash {
          @extend .arrowflashinline;
        }
      }
    }
  }

  &:hover,
  &:active {
    filter: brightness(105%);
  }
}

.textandimageblock-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fade-out {
  opacity: 0.4;
  -webkit-transition: 2s all ease-in-out;
  -moz-transition: 2s all ease-in-out;
  -o-transition: 2s all ease-in-out;
  transition: 2s all ease-in-out;

  &:hover {
    opacity: 1;
  }
}

form {
  img.captcha {
    float: left;
  }

  input#id_wagtailcaptcha_1 {
    float: left;
    margin-left: 3px;
    width: 250px;
    margin-top: -1px;
  }

  label {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  .captcha-prompt {
    margin-top: -1.2rem;
    margin-bottom: 1.2rem;
  }
}

footer {
  border-top: 10px solid $bearbox_blue;

  .footer-icon-wrapper {
    position: relative;

    .footer-icon {
      position: absolute;
      bottom: 0;
      left: calc(50% - 50px);
      width: 100px;
    }
  }
}
